@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&family=Oxygen:wght@300;400;700&family=Shadows+Into+Light&display=swap);
html {
  /* Breakpoints */
  --sm: 30em;
  --md: 48em;
  /* Colors */
  --white: #ffffff;
  --black: #000000;
  /* Light Shades */
  --light100: #f1f4ed;
  --light200: #e3eadc;
  --light300: #d6dfca;
  --light400: #c8d4b9;
  /* Light Accent */
  --light-accent100: #98bcb3;
  --light-accent200: #80aa9f;
  --light-accent300: #67988b;
  --light-accent400: #577e74;
  /* Primary Shades */
  --primary-brand100: #97c6c3;
  --primary-brand200: #7cb6b2;
  --primary-brand300: #62a6a1;
  --primary-brand400: #518c88;
  --primary-brand500: #42716d;
  --primary-brand600: #335553;
  /* Dark Accent */
  --dark-accent100: #537e77;
  --dark-accent200: #42645f;
  --dark-accent300: #324a46;
  --dark-accent400: #21302e;
  /* Dark Shades */
  --dark100: #303341;
  --dark200: #242631;
  --dark300: #181a20;
  --dark400: #0c0d10;
  /* Gray */
  --gray100: #eaeaea;
  --gray200: #d6d6d6;
  --gray300: #c2c2c2;
  --gray400: #8f8f8f;
  /* Health */
  --success-color: #53ac68;
  --warning-color: #d09c30;
  --danger-color: #f44336;
  /* Space */
  --space-rpx2: 0.125rem;
  --space-rpx4: 0.25rem;
  --space-rpx8: 0.5rem;
  --space-rpx12: 0.75rem;
  --space-rpx16: 1rem;
  --space-rpx20: 1.25rem;
  --space-rpx24: 1.5rem;
  --space-rpx28: 1.75rem;
  --space-rpx32: 2rem;
  --space-rpx36: 2.25rem;
  --space-rpx40: 2.5rem;
  /* Font Scale */
  --scaler: 5vw;
}

[data-theme="mk-light"] {
  /* Fonts */
  --primary-font: "Open Sans", sans-serif;
  --header-font: "Oxygen", sans-serif;
  --cursive-text: "Shadows Into Light", cursive;
  /* Radius */
  --large-radius: 8px;
  --small-radius: 2px;
  /* Primary Text Colors */
  --primary-text-color: var(--dark300);
  --secondary-text-color: var(--gray400);
  /* Primary and Secondary Colors */
  --primary-color: var(--primary-brand300);
  --secondary-color: var(--dark100);
  /* Hover */
  --hover-color: var(--primary-brand500);
  --hover-text-color: var(--white);
  --hover-outlined-bg-color: var(--light100);
  /* Focus */
  --focus-color: var(--primary-brand500);
  --focus-text-color: var(--white);
  --focus-outlined-bg-color: var(--light100);
  /* Active */
  --active-color: var(--primary-brand600);
  --active-text-color: var(--white);
  --active-outlined-bg-color: var(--light200);
  /* Focus Outline */
  --outline: 2px solid var(--light-accent100);
  --outline-offset: 2px;
  --outline-transition: outline-offset 300ms ease;
  /* error */
  --error-color: var(--danger-color);
  /* Disabled Colors */
  --disabled-color: var(--gray100);
  --disabled-text-color: var(--gray300);
  /* Borders */
  --border-color: var(--gray300);
  /* Box Shadow */
  --box-shadow: 0 1px 5px 0 rgba(217, 217, 217, 0.12),
    0 3px 1px 0 rgba(217, 217, 217, 0.12), 0 2px 2px 0 rgba(217, 217, 217, 0.12);
  /* Checkbox and Radio Size */
  --radio-checkbox-size: 1.5rem;
  --radio-checkbox-border: 1px solid var(--gray400);
  /* Cards */
  --card-bg-color: var(--white);
}

